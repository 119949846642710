import dayjs from "dayjs";
import CryptoJS from "crypto-js";
import { photoOrders } from "@/config/constants";
import { getCookie } from "../helper/cookie";
import DeviceDetector from "device-detector-js";
import * as Sentry from "@sentry/vue";
import { SENTRY_LEVEL } from "./constant";
import Jimp from "jimp";
import * as UTIF from "utif";
import Pica from "pica";
import imageCompression from 'browser-image-compression';

export function getIV() {
  const queryString = getCookie("qs");
  const urlDecoded = decodeURIComponent(queryString).replace(/ /g, "+");
  const position = urlDecoded.search("enc=");
  let encString = urlDecoded.slice(position + 4);
  const parts = encString.split(':');
  return parts[0];
}

export default function regex(regx, value) {
  const regex = new RegExp(regx);
  return regex.test(value);
}

export const isObject = v => typeof v === 'object' && v !== null

export const isEmpty = v =>
  !v ||
  (Array.isArray(v) && v.length === 0) ||
  (isObject(v) && Object.keys(v).length === 0);

export function formatNumberWithComma(value) {
  return value.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function removeFormatNumberWithComma(value) {
  return value.toString().replace(/,/g, '');
}

export function formatNumberWithSlash(value) {
  return value.toString().replace(/\D/g, "").replace(/\B(?=(\d{2})+(?!\d))/g, "/");
}

export function originalDateFormatFromSlash(value) {
  const dateArray = value.split("/");
  const arrangedArray =
    dateArray.length === 3
      ? [dateArray[1], dateArray[0], dateArray[2]]
      : [dateArray[0], "01", dateArray[1]];
  return dayjs(arrangedArray.join("/")).format("YYYY-MM-DD");
}

export const decrypt = (encryptedData, encKey, encIV = getIV()) => {
  const decrypted = CryptoJS.AES.decrypt(encryptedData, CryptoJS.enc.Hex.parse(encKey), { iv: CryptoJS.enc.Base64.parse(encIV) });
  return CryptoJS.enc.Utf8.stringify(decrypted);
}

const AusState = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];

const NZRegions = [
  'NORTHLAND',
  'AUCKLAND',
  'WAIKATO',
  'BAY OF PLENTY',
  'GISBORNE',
  "HAWKE'S BAY",
  'TARANAKI',
  'MANAWATŪ-WHANGANUI',
  'WELLINGTON',
  'TASMAN',
  'NELSON',
  'MARLBOROUGH',
  'WEST COAST',
  'CANTERBURY',
  'OTAGO',
  'SOUTHLAND',
];

const removeValue = (arr, index) => {
  arr.splice(index, 1);
};

export const locationValMap = (val, isNZ) => {
  // Split location string into array
  let locationArray = val.toUpperCase().split(' ');

  // Init
  let state = '';
  let postCode = '';

  // Detect state
  const isSingleWord = (str) => str.split(' ').length === 1;

  for (const region of [...(isNZ ? NZRegions : AusState)]) {
    const includesRegion = isSingleWord(region)
      ? locationArray.includes(region)
      : locationArray.join(' ').includes(region);

    if (includesRegion) {
      state = region.toUpperCase();
      break; // exit the loop early if a match is found
    }
  }

  // Remove value if valid
  if (state !== '') {
    if (isSingleWord(state)) {
      removeValue(
        locationArray,
        locationArray.findIndex((value) => value === state),
      );
    } else {
      locationArray = val
        .toUpperCase()
        .replace(state, '')
        .split(' ')
        .filter((string) => string);
    }
  }

  // Detect postcode and remove value if valid
  locationArray.forEach((value, index) => {
    if (!isNaN(+value)) {
      postCode = value;
      removeValue(locationArray, index);
    }
  });

  // Create surbub value with the rest of splitted location string array
  const suburb = locationArray.join(' ').toUpperCase();

  return {
    suburb,
    state,
    postCode,
  };
};

export function getDeviceInfo() {
  const deviceDetector = new DeviceDetector();
  return deviceDetector.parse(navigator.userAgent);
}

export function separatesUploadImages(uploadImages) {
  const images = [];
  const oldImages = [];
  uploadImages.forEach(image => {
    if (image.isAutoplay) {
      oldImages.push(image);
    } else {
      images.push(image);
    }
  })
  return { images, oldImages };
}

export function apiEndpointTransform(hostname) {
  if (['localhost', 'autoflip.co.nz', 'nzlp'].some(host => hostname.includes(host)))
    return process.env.VUE_APP_BASE_API_ENDPOINT;

  const isNotProd = hostname.includes('psp-')
  let backendHost = ''
  if (isNotProd) {
    backendHost = `api.${hostname}`
  } else {
    backendHost = hostname.replace(/sell|mycar/gi, "api");
  }
  return `https://${backendHost}`
}

export function getParamFromURL(url, key) {
  const params = new URLSearchParams(new URL(url).search);
  return params.get(key);
}

export function setFavicon(faviconUrl) {
  const favicon = document.querySelector('link[rel="icon shortcut"]');

  if (favicon) {
    favicon.href = faviconUrl || `${process.env.VUE_APP_BASE}/favicon.ico`;
  } else {
    const newFavicon = document.createElement('link');
    newFavicon.rel = 'icon';
    newFavicon.type = 'image/x-icon';
    newFavicon.href = faviconUrl || `${process.env.VUE_APP_BASE}/favicon.ico`;
    document.head.appendChild(newFavicon);
  }
}

export function setTitle(newTitle) {
  if (newTitle) {
    document.title = newTitle;
  }
}

export function imageObjectsFromUrls(urls, isAutoplay, deletedUrls = []) {
  const imageObjects = urls.map((url, index) => {
    const filename = url.substring(url.lastIndexOf("/") + 1);
    const id = filename;
    const photoOrdersIndex =
      index < photoOrders.length - 1
        ? index
        : photoOrders.length - 1;
    return {
      id,
      filename,
      url,
      name: filename,
      key: photoOrders[photoOrdersIndex].key,
      title: photoOrders[photoOrdersIndex].title,
      type: photoOrders[photoOrdersIndex].type,
      onUpload: false,
      progress: 100,
      isAutoplay,
      deleted: deletedUrls.includes(url),
    };
  });
  return imageObjects;
}

export function getSentryTag() {
  return `mycar_${
    getParamFromURL(window.location.href, "locid") === "2" ||
    window.location.hostname.includes("nz")
      ? "nz"
      : "au"
  }`;
}

export function logError(message) {
  console.error(message);
  Sentry.captureMessage(message, SENTRY_LEVEL.ERROR);
}

export async function tiffToBlob(file) {
  const fileReader = new FileReader();

  return new Promise((resolve, reject) => {
    fileReader.onload = async () => {
      try {
        const tiffData = new Uint8Array(fileReader.result);

        // Decode the TIFF file using UTIF
        const pages = UTIF.decode(tiffData);
        UTIF.decodeImage(tiffData, pages[0]); // Decode the first page

        const { width, height, data } = pages[0];

        // Create a new Jimp image using the decoded data
        const jimpImage = new Jimp({ width, height, data });

        // Get a buffer in PNG format and convert it to Blob
        const imageBuffer = await jimpImage.getBufferAsync(Jimp.MIME_JPEG);
        const blob = new Blob([imageBuffer], { type: Jimp.MIME_JPEG });

        resolve(blob);
      } catch (error) {
        reject(error);
      }
    };

    fileReader.onerror = reject;
    fileReader.readAsArrayBuffer(file);
  });
}

export async function resizeImage(imageFile, options) {
  const pica = new Pica();
  let quality = 0.9; // Initial quality
  const maxFileSize = options.maxSizeMB * 1024 * 1024; // Max file size in bytes
  const maxSize = options.maxWidthOrHeight; // Maximum width or height

  // Create an Image element from the File or Blob
  const imageElement = await loadImageFromFile(imageFile);

  // Set the target dimensions, preserving the aspect ratio
  let { width, height } = imageElement;
  if (width > height) {
    if (width > maxSize) {
      height = Math.round((height * maxSize) / width);
      width = maxSize;
    }
  } else {
    if (height > maxSize) {
      width = Math.round((width * maxSize) / height);
      height = maxSize;
    }
  }

  const canvas = document.createElement("canvas");
  canvas.width = width;
  canvas.height = height;

  try {
    let resizedBlob;
    do {
      // Resize the image using Pica
      await pica.resize(imageElement, canvas);

      // Convert the resized canvas to a Blob with specified quality
      resizedBlob = await pica.toBlob(canvas, options.fileType, quality);

      // Decrease quality if the file size exceeds the limit
      quality -= 0.1;
    } while (resizedBlob.size > maxFileSize && quality > 0);

    return resizedBlob;
  } catch (error) {
    console.error(error);
    return await imageCompression(imageFile, options);
  }
}

function loadImageFromFile(imageFile) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = reject;
      image.src = reader.result;
    };
    reader.onerror = reject;
    reader.readAsDataURL(imageFile);
  });
}

export const LeadSourceStringMap = {
  "lp001": 'Private seller website',
  "lp002": 'Private seller website 02',
  "lp004": 'Private seller website 04',
  "lp006": 'Private seller website 06',
  "lp007": 'Dealers Sell a Car',
  "lp008": 'Self Service',
  'Drive': 'Drive.com',
};